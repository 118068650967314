
import React from 'react'
import { Link } from 'react-router-dom'
class Header extends React.Component {
    render() {
        return (
            <div>
                <header>
                    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
                        <div className="container">
                            <Link to="/" className="navbar-brand"  ><img src="assets/images/logo.svg" alt="logo" /> </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                    <li className="nav-item" data-name="home">
                                        <Link to="/">হোম </Link>
                                    </li>
                                    <li className="nav-item" data-name="story">
                                        <Link to={{ pathname: "https://www.anun.xyz/Story" }} target="_parent"> আমাদের গল্প </Link>
                                    </li>
                                   
                                    <li className="nav-item" data-name="live-chat">
                                        <Link to={{ pathname: "https://www.anun.xyz/Livechat" }} target="_parent">🎧 লাইভ সাপোর্ট </Link>
                                    </li>
                                    <li className="nav-item" data-name="live-chat">
                                    <Link class='social-icon-card-github'to={{ pathname: "https://www.facebook.com/anun.xyz" }} target="_blank"><i class='fab fa-facebook' /> </Link>
                                    <Link class='social-icon-card-github'to={{ pathname: "https://www.instagram.com/anunapp" }}target="_blank"><i class='fab fa-instagram' /> </Link>   
                                    <Link class='social-icon-card-github'to={{ pathname: "https://www.linkedin.com/company/anun" }}target="_blank"><i class='fab fa-linkedin' /> </Link> 
                                    <Link class='social-icon-card-github'to={{ pathname: "https://t.me/anun_rider" }} target="_blank"><i class='fab fa-telegram' /> </Link>
                                    
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        )
    }
}
export default Header