
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation, withRouter } from "react-router-dom";
import Footer from './components/Footer';
import Header from './components/Header';
import Covid from './pages/Covid';
import Direction from './pages/Direction';
import Home from './pages/Home';
import Instrument from './pages/Instrument';
import Insurance from './pages/Insurance';
import NotFound from './pages/NotFound';
import Permission from './pages/Permission';
import Safety from './pages/Safety';



function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)



function App() {
  return (
    <div>
      <Router>
      <ScrollToTop>
        <Header />

        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/Direction' component={Direction} />
          <Route path='/Instrument' component={Instrument} />
          <Route path='/Insurance' component={Insurance} />
          <Route path='/Permission' component={Permission} />
          <Route path='/Safety' component={Safety} />
          <Route path='/Covid' component={Covid} />
          <Route component={NotFound} />
          <Home />
        </Switch>

        <Footer />
        </ScrollToTop>
      </Router>

    </div>

  );
}

export default App;
