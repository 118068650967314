import React from 'react'
import { Helmet } from 'react-helmet'

class Safety extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>নিরাপত্তা তথ্য | আনুন</title>
                    <meta name="description" content="নিরাপত্তার স্বার্থে কি কি করবেন তা জানুন এখান থেকে" />
                    <meta itemProp="name" content="নিরাপত্তা তথ্য | আনুন" />
                    <meta itemProp="description" content="নিরাপত্তার স্বার্থে কি কি করবেন তা জানুন এখান থেকে" />
                    <meta itemProp="image" content="/assets/images/icon.png" />
                    <meta property="og:url" content="https://rider.anun.xyz/Safety" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="নিরাপত্তা তথ্য | আনুন" />
                    <meta property="og:description" content="নিরাপত্তার স্বার্থে কি কি করবেন তা জানুন এখান থেকে" />
                    <meta property="og:image" content="/assets/images/icon.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="নিরাপত্তা তথ্য | আনুন" />
                    <meta name="twitter:description" content="নিরাপত্তার স্বার্থে কি কি করবেন তা জানুন এখান থেকে" />
                    <meta name="twitter:image" content="/assets/images/icon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                </Helmet>
                <section className="page-title pt115">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="section-title alt-left">
                                    <h2>নির্দেশনা </h2>
                                    <p>রাইডার হিসেবে আপনার প্রতি আনুনের বেসিক নির্দেশনা দেয়</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ptb100 pt60">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="job-details-info">
                                    <h3>☑ নির্দেশনা</h3>
                                    <p>
                                        ☑ সাইকেল বা মোটরসাইকেল পার্কিং এর সময় লক ব্যবহার করুন <br />
                ☑ নিয়ম ভঙ্গ করে সাইকেল বা মোটরসাইকেল চালাবেন না <br />
                ☑ হেলমেট পরিধান করুন <br />
                                    </p>
                                    <p />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}
export default Safety