import React from 'react'
import { Helmet } from 'react-helmet'
class Permission extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>অনুমতিপত্র | আনুন</title>
                    <meta name="description" content="লকডাউনের কার্যক্রম চালু রাখার অনুমতিপত্র" />
                    <meta itemProp="name" content="অনুমতিপত্র | আনুন" />
                    <meta itemProp="description" content="লকডাউনের কার্যক্রম চালু রাখার অনুমতিপত্র" />
                    <meta itemProp="image" content="/assets/images/icon.png" />
                    <meta property="og:url" content="https://rider.anun.xyz/Permission" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="অনুমতিপত্র | আনুন" />
                    <meta property="og:description" content="লকডাউনের কার্যক্রম চালু রাখার অনুমতিপত্র" />
                    <meta property="og:image" content="/assets/images/icon.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="অনুমতিপত্র | আনুন" />
                    <meta name="twitter:description" content="লকডাউনের কার্যক্রম চালু রাখার অনুমতিপত্র" />
                    <meta name="twitter:image" content="/assets/images/icon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                </Helmet>
                <section class="page-title pt115">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="section-title alt-left">
                                    <h2>সরকারি অনুমোদন পত্র</h2>
                                    <p>বর্তমান পরিস্থিতিতে গণপ্রজাতন্ত্রী বাংলাদেশ সরকার জরুরি সেবার অন্তর্ভুক্তি হিসেবে আনুনকে কার্যক্রম চালানোর অনুমতি প্রদান করেছেন।</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="ptb100 pt60">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="job-details-info">


                                    <img src="assets/images/1.webp" alt="permission" height="540" width="370" /> <img src="assets/images/ntc.png" alt="permission" height="540" width="370" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}
export default Permission