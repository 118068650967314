import React from 'react'
import { Helmet } from 'react-helmet'
class Insurance extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>ইন্সুরেন্স | আনুন</title>
                    <meta name="description" content="সেফটির জন্য নিশ্চিত করুন আপনার ইন্সুরেন্স" />
                    <meta itemProp="name" content="ইন্সুরেন্স | আনুন" />
                    <meta itemProp="description" content="সেফটির জন্য নিশ্চিত করুন আপনার ইন্সুরেন্স" />
                    <meta itemProp="image" content="/assets/images/icon.png" />
                    <meta property="og:url" content="https://rider.anun.xyz/Insurance" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="ইন্সুরেন্স | আনুন" />
                    <meta property="og:description" content="সেফটির জন্য নিশ্চিত করুন আপনার ইন্সুরেন্স" />
                    <meta property="og:image" content="/assets/images/icon.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="ইন্সুরেন্স | আনুন" />
                    <meta name="twitter:description" content="সেফটির জন্য নিশ্চিত করুন আপনার ইন্সুরেন্স" />
                    <meta name="twitter:image" content="/assets/images/icon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                </Helmet>
                <section className="page-title pt115">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="section-title alt-left">
                                    <h2>ইনসুরেন্স পলিসি</h2>
                                    <p>
                                        যেকোন সময় ঘটে যেতে পারে দূর্ঘটনা তাই নিজেকে ও পরিবারকে সুরক্ষিত
                                        রাখতে আনুন নিয়ে এসেছে রাইডার ইন্সুরেন্স।
              </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ptb100 pt60">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="job-details-info">
                                    <h3>
                                        *** বছরে মাত্র ৭০০ টাকায় পেতে পারেন ৫ লাখ টাকা পর্যন্ত কভারেজ
                ***{" "}
                                    </h3>
                                    <br />
                                    <h2>কিভাবে পাবেন?</h2>
                                    <p>
                                        ☑ আনুনে রাইডার হিসেবে জয়েন করার ১০ দিনের মধ্যে প্রয়োজনীয়
                                        কাগজপত্র এবং টাকা জমা দিয়ে ইন্সুরেন্স নিতে পারেন। অথবা আনুন
                                        আপনার প্রতিদিন এর আয় থেকে ২০ টাকা কেটে ইন্সুরেন্স এর জন্য মোট
                ৭০০ টাকা নিয়ে নিবে। <br />
                                    </p>
                                    <p>
                                        ☑ যেকোন দূর্ঘটনার স্বীকার হলে সকল ডাক্তারি নথি সংরক্ষণ করুন এবং
                আনুনকে জানান আপনার ইন্সুরেন্স কাভারেজে কাজ করার জন্য। <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}
export default Insurance