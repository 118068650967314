import { BackTop } from 'antd';
import "firebase/analytics";
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
const firebaseConfig = {
  apiKey: "AIzaSyCKvmXri9sdtwED8Ntti9VEGni3creKfQA",
  authDomain: "rider-9bef8.firebaseapp.com",
  projectId: "rider-9bef8",
  storageBucket: "rider-9bef8.appspot.com",
  messagingSenderId: "980553640644",
  appId: "1:980553640644:web:9f2f89b24543d05f5cb791",
  measurementId: "G-EZ243ZH4ES"
};

firebase.initializeApp(firebaseConfig);
ReactDOM.render(
  <React.StrictMode>
    <App />
    <BackTop />
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
