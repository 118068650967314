import React from 'react'
import { Helmet } from 'react-helmet'
class Covid extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>করোনা ভাইরাস | আনুন</title>
                    <meta name="description" content="করোনা ভাইরাস থেকে সচেতন থাকতে মেনে চলুন" />
                    <meta itemProp="name" content="করোনা ভাইরাস | আনুন" />
                    <meta itemProp="description" content="করোনা ভাইরাস থেকে সচেতন থাকতে মেনে চলুন" />
                    <meta itemProp="image" content="/assets/images/icon.png" />
                    <meta property="og:url" content="https://rider.anun.xyz/Covid" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="করোনা ভাইরাস | আনুন" />
                    <meta property="og:description" content="করোনা ভাইরাস থেকে সচেতন থাকতে মেনে চলুন" />
                    <meta property="og:image" content="/assets/images/icon.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="করোনা ভাইরাস | আনুন" />
                    <meta name="twitter:description" content="করোনা ভাইরাস থেকে সচেতন থাকতে মেনে চলুন" />
                    <meta name="twitter:image" content="/assets/images/icon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                </Helmet>
                <section class="page-title pt115">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="section-title alt-left">
                                    <h2>করোনা ভাইরাস </h2>
                                    <p>করোনা ভাইরাস এর প্রতিকার নেই এখন পর্যন্ত তাই সচেতনতার মাধ্যমে তা প্রতিরোধ করি</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="ptb100 pt60">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="job-details-info">
                                    <p>&#9745;   সাবান-পানি দিয়ে একটু পর পর হাত ধোবেন অথবা হ্যান্ড স্যানিটাইজার ব্যবহার করুন।  <br />
                                        &#9745;   মাস্ক পরিধান করে বাইরে বের হবেন। <br />
                                            &#9745;   হ্যান্ড গ্লাভস ব্যবহার করুন যেকোন কিছু স্পর্শের সময়। <br />
                                                &#9745;   বাইক বা সাইকেল জীবানুমুক্ত করুন।<br />
                                                    &#9745;   ৩ মিটার নিরাপদ দূরত্ব বজায় রাখুন। <br />
                                                        &#9745;   প্রতিদিন শরীরের তাপমাত্রা মাপুন। <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }

}
export default Covid