import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
class Home extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>রাইডার পোর্টাল | আনুন</title>
                    <meta name="description" content="রাইডার হিসেবে জয়েন করার জন্য প্রয়োজনীয় সবকিছু এখন রাইডার পোর্টালে" />
                    <meta itemProp="name" content="রাইডার পোর্টাল | আনুন" />
                    <meta itemProp="description" content="রাইডার হিসেবে জয়েন করার জন্য প্রয়োজনীয় সবকিছু এখন রাইডার পোর্টালে" />
                    <meta itemProp="image" content="/assets/images/icon.svg" />
                    <meta property="og:url" content="https://rider.anun.xyz" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="রাইডার পোর্টাল | আনুন" />
                    <meta property="og:description" content="রাইডার হিসেবে জয়েন করার জন্য প্রয়োজনীয় সবকিছু এখন রাইডার পোর্টালে" />
                    <meta property="og:image" content="/assets/images/icon.svg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="রাইডার পোর্টাল | আনুন" />
                    <meta name="twitter:description" content="রাইডার হিসেবে জয়েন করার জন্য প্রয়োজনীয় সবকিছু এখন রাইডার পোর্টালে" />
                    <meta name="twitter:image" content="/assets/images/icon.svg" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                </Helmet>
                <section class="about-scetion ptb100">
                    <div class="container">
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                    <img src="assets/images/rider 1.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>কেন হবো রাইডার?</h2>
                                    <p>কারণ আনুন এর বিভিন্ন সার্ভিসে রাইডার হিসেবে জয়েন করলে প্রতি অর্ডারে পাচ্ছেন চলনসই স্যালারী। আর ব্যাচভিত্তিক কাজ করলে বোনাস।</p>
                                </div>
                            </div>
                        </div>
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7 order-2">
                                <div class="about-image">
                                    <img src="assets/images/rider 2.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-1">
                                <div class="about-info pr35">
                                    <h2>আনুন কেন বেছে নেবেন?</h2>
                                    <p>এখানে থাকছে ইচ্ছে মতো ব্যাচ বেছে নেওয়ার সুযোগ।সেটা হতে পারে পার্ট টাইম বা ফুল টাইম। অনেক গুলো সার্ভিসে রাইডার হওয়া মানেই অনেক বেশি আয় প্রতিদিন।</p>
                                </div>
                            </div>
                        </div>
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                    <img src="assets/images/rider 3.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>কি কি দরকার?</h2>
                                    <p>&#9745; একটি অ্যান্ড্রয়েড ফোন যার ভার্সন ৫ নূন্যতম।  <br />
&#9745; সাইকেল বা বাইক।  <br />
&#9745; এনআইডি / জন্ম নিবন্ধন/পাসপোর্ট এর কপি  <br />
&#9745; ১ কপি ছবি।  <br />
&#9745; বাবা/মা/ভাই/বোন এর এনআইডির কপি।
  <br />&#9745; ড্রাইভিং লাইসেন্স এর কপি। <br />
&#9745; নগদ মোবাইল ব্যাংকিং একাউন্ট।</p>
                                </div>
                            </div>
                        </div>





                        <div id="e" class="row mb120">
                            <div class="col-12 col-md-7 order-2">
                                <div class="about-image">
                                    <img src="assets/images/rider 6.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-1">
                                <div class="about-info pr35">
                                    <h2>ডেলিভারি ইন্সট্রুমেন্ট</h2>
                                    <p>আনুনে রাইডার হিসেবে জয়েন করলেই পাবেন বিভিন্ন ডেলিভারি ইন্সট্রুমেন্ট</p>
                                    <br />
                                    <Link to="/Instrument"><button class="btn btn-dark">আরো জানুন &#8658;</button></Link>

                                </div>
                            </div>
                        </div>

                        <div id="g" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                    <img src="assets/images/rider 7.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>নির্দেশনা</h2>
                                    <p>রাইডার হিসেবে আপনার প্রতি আনুনের বেসিক নির্দেশনা দেয়</p>
                                    <br />
                                    <Link to="/Direction"><button class="btn btn-dark">আরো জানুন &#8658;</button></Link>
                                </div>
                            </div>
                        </div>
                        <div id="s" class="row mb120">
                            <div class="col-12 col-md-7 order-2">
                                <div class="about-image">
                                    <img src="assets/images/rider 8.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-1">
                                <div class="about-info pr35">
                                    <h2>নিরাপত্তা তথ্য</h2>
                                    <p>নিরাপত্তার স্বার্থে কি কি করবেন তা জানুন এখান থেকে</p>
                                    <br />
                                    <Link to="/Safety"><button class="btn btn-dark">আরো জানুন &#8658;</button></Link>
                                </div>
                            </div>
                        </div>
                        <div id="g" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                    <img src="assets/images/rider 9.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>কোভিড-১৯</h2>
                                    <p>করোনা ভাইরাস থেকে সচেতন থাকতে মেনে চলুন</p>
                                    <br />
                                    <Link to="/Covid"><button class="btn btn-dark">আরো জানুন &#8658;</button></Link>
                                </div>
                            </div>
                        </div>
                        <div id="s" class="row mb120">
                            <div class="col-12 col-md-7 order-2">
                                <div class="about-image">
                                    <img src="assets/images/rider 10.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-1">
                                <div class="about-info pr35">
                                    <h2>অনুমতিপত্র</h2>
                                    <p>লকডাউনের কার্যক্রম চালু রাখার  অনুমতিপত্র</p>
                                    <br />
                                    <Link to="/Permission"><button class="btn btn-dark">আরো জানুন &#8658;</button></Link>
                                </div>
                            </div>
                        </div>
                        <div id="g" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                    <img src="assets/images/rider 11.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>ইন্সুরেন্স কভারেজ</h2>
                                    <p>সেফটির জন্য নিশ্চিত করুন আপনার ইন্সুরেন্স</p>

                                    <br />
                                    <Link to="/Insurance"><button class="btn btn-dark">আরো জানুন &#8658;</button></Link>                      </div>
                            </div>
                        </div>
                        <div id="s" class="row mb120">
                            <div class="col-12 col-md-7 order-2">
                                <div class="about-image">
                                    <img src="assets/images/rider 12.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5 order-1">
                                <div class="about-info pr35">
                                    <h2>পেমেন্ট স্কিম</h2>
                                    <p>আনুনের সকল ডেলিভারির পেমেন্ট সংক্রান্ত</p>
                                    <br />

                                    <a href="new/index.php" class="btn btn-dark">সাইনআপ করলেই বিস্তারিত </a>
                                </div>
                            </div>
                        </div>
                        <div id="ok" class="row mb120">
                            <div class="col-12 col-md-7">
                                <div class="about-image">
                                    <img src="assets/images/rider 5.jpg" alt="anun-cdn" />
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="about-info pl35">
                                    <h2>রেফার করলেই বোনাস</h2>
                                    <p>রাইডার রেফার করলেই পাবেন বোনাস</p>
                                    <br />
                                    <a href="new/index.php" class="btn btn-dark">সাইনআপ করলেই বিস্তারিত  </a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="call-to-action pt100">
                                    <h2>আজই রেজিষ্ট্রেশন করুন, ডকুমেন্টস ঠিক থাকলে কাল থেকে কাজ শুরু করতে পারবেন</h2>
                                    <a href="new/index.php" class="btn btn-outline-dark btn-lg btn-block">রেজিষ্ট্রেশন করুন </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


            </div >


        )
    }

}

export default Home