import React from 'react'
import { Helmet } from 'react-helmet'
class Instrument extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>ডেলিভারি ইন্সট্রুমেন্ট | আনুন</title>
                    <meta name="description" content="আনুনে রাইডার হিসেবে জয়েন করলেই পাবেন বিভিন্ন ডেলিভারি ইন্সট্রুমেন্ট" />
                    <meta itemProp="name" content="ডেলিভারি ইন্সট্রুমেন্ট | আনুন" />
                    <meta itemProp="description" content="আনুনে রাইডার হিসেবে জয়েন করলেই পাবেন বিভিন্ন ডেলিভারি ইন্সট্রুমেন্ট" />
                    <meta itemProp="image" content="/assets/images/icon.png" />
                    <meta property="og:url" content="https://rider.anun.xyz/Instrument" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="ডেলিভারি ইন্সট্রুমেন্ট | আনুন" />
                    <meta property="og:description" content="আনুনে রাইডার হিসেবে জয়েন করলেই পাবেন বিভিন্ন ডেলিভারি ইন্সট্রুমেন্ট" />
                    <meta property="og:image" content="/assets/images/icon.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="ডেলিভারি ইন্সট্রুমেন্ট | আনুন" />
                    <meta name="twitter:description" content="আনুনে রাইডার হিসেবে জয়েন করলেই পাবেন বিভিন্ন ডেলিভারি ইন্সট্রুমেন্ট" />
                    <meta name="twitter:image" content="/assets/images/icon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                </Helmet>
                <section class="page-title pt115">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="section-title alt-left">
                                    <h2>ডেলিভারি ইন্সট্রুমেন্ট </h2>

                                    <p>আনুনে রাইডার হিসেবে জয়েন করে আপনি পেতে পারেন</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="ptb100 pt60">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="job-details-info">
                                    <h3>&#9745; ১টি আনুন রাইডার্স টি-শার্ট</h3>
                                    <h3>&#9745; ১টি থার্মাল ব্যাগ</h3>
                                    <h3>&#9745; ১ টি ডেলিভারি ব্যাগ</h3>

                                    <center><h3> বিশেষ নির্দেশনা  </h3></center> <br />
                                    <p> &#10004; আপনাকে যেসব ইন্সট্রুমেন্ট প্রদান করা হবে তার বিনিময় সিকিউরিটি হিসেবে ৮০০ টাকা স্যালারী থেকে কেটে রাখা হবে। আপনি যখন আনুন ছেড়ে দিবেন তখন ব্যাগ জমা দিয়ে ৮০০ টাকা বুঝে নিন ।&#10004;
</p>

                                    <center><h2>যেভাবে টাকা কেটে নেওয়া হবে-</h2><br /><h3>দৈনিক ১৫টাকা</h3> </center>
                                    <br />

                                    <center><table>
                                        <tr>
                                            <th>মাস</th>
                                            <th>পরিমাণ</th>

                                        </tr>
                                        <tr>
                                            <td>১ম মাসে</td>
                                            <td>৩৫০ টাকা</td>

                                        </tr>
                                        <tr>
                                            <td>২য় মাসে</td>
                                            <td>৪৫০ টাকা</td>

                                        </tr>



                                    </table></center>
                                    <br />

                                    <p> বিঃ দ্রঃ আপনাকে দেওয়া ইন্সট্রুমেন্টগুলো প্রতি সপ্তাহে ২বার পরিষ্কার করুন। লক্ষ্য রাখবেন সেগুলো যেন নষ্ট না হয়।ব্যবহার অযোগ্য হলে আপনার সিকিউরিটি মানি থেকে ব্যাগের দাম কর্তন করা হবে
</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}
export default Instrument