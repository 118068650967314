import React from 'react'
import { Helmet } from 'react-helmet'
class Direction extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>নির্দেশনা | আনুন</title>
                    <meta name="description" content="রাইডার হিসেবে আপনার প্রতি আনুনের বেসিক নির্দেশনা দেয়" />
                    <meta itemProp="name" content="নির্দেশনা | আনুন" />
                    <meta itemProp="description" content="রাইডার হিসেবে আপনার প্রতি আনুনের বেসিক নির্দেশনা দেয়" />
                    <meta itemProp="image" content="/assets/images/icon.png" />
                    <meta property="og:url" content="https://rider.anun.xyz/Direction" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="নির্দেশনা | আনুন" />
                    <meta property="og:description" content="রাইডার হিসেবে আপনার প্রতি আনুনের বেসিক নির্দেশনা দেয়" />
                    <meta property="og:image" content="/assets/images/icon.png" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="নির্দেশনা | আনুন" />
                    <meta name="twitter:description" content="রাইডার হিসেবে আপনার প্রতি আনুনের বেসিক নির্দেশনা দেয়" />
                    <meta name="twitter:image" content="/assets/images/icon.png" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                </Helmet>
                <section className="page-title pt115">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="section-title alt-left">
                                    <h2>নির্দেশনা </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ptb100 pt60">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="job-details-info">
                                    <h3>☑ পোশাক নির্দেশনা</h3>
                                    <p>
                                        ☑ অফিশিয়াল টিশার্ট অথবা শার্ট <br />
                ☑ ফুল প্যান্ট <br />
                ☑ কেডস বা মার্জিত জুতা <br />
                                    </p>
                                    <h3>☑ আনুষঙ্গিক নির্দেশনা</h3>
                                    <p>
                                        ☑ মোবাইল সম্পূর্ণ চার্জ করে রাইড শুরু করুন <br />
                ☑ ফোনে যথেষ্ট ব্যালেন্স এবং ডেটা রাখুন <br />
                ☑ ব্যাকআপ হিসেবে পাওয়ার ব্যাংক রাখতে পারেন <br />
                ☑ খুচরা টাকা সঙ্গে রাখুন <br />
                ☑ সাইকেল বা মোটরসাইকেল পরীক্ষা করে নিন <br />
                ☑ জ্বালানি নিশ্চিত করুন <br />
                ☑ মোটরসাইকেল সংক্রান্ত সকল কাগজ সঙ্গে রাখুন <br />{" "}
                                    </p>
                                    <h3>☑ আচারণ সংক্রান্ত নির্দেশনা</h3>
                                    <p>
                                        ☑ কখনো কাস্টমার ও পার্টনারদের সাথে খারাপ আচারণ করবেন না <br />
                ☑ কোন ঝামেলা সৃষ্টি হলে কল সেন্টারে কল করে জানান, তারা সমাধান
                করবে <br />
                ☑ ইন্টারনাল কোন তথ্য বাহিরে প্রকাশ করবেন না, আনুন নিয়ে সোশ্যাল
                মিডিয়ায় অযাচিত কিছু লিখবেন না <br />
                ☑ কাস্টমারের ফোন নাম্বার ও তথ্যের প্রাইভেসি বজায় রাখুন <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}
export default Direction