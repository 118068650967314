import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
class NotFound extends React.Component {

    render() {

        return (

            <div>
                <Helmet>
            
                    <meta charSet="utf-8" />
                    <title>৪০৪ ইরোর </title>
                    <link rel="canonical" href="https://.anun.xyz/NotFound" />
                    
                    <meta name="title" content="৪০৪ ইরোর "></meta>
                    <meta name="description" content="৪০৪ ইরোর " />
                    <meta name="keywords" content="৪০৪ ইরোর " />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                
                    
                    <meta property="og:url" content="https://rider.anun.xyz/NotFound" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="৪০৪ ইরোর " />
                    <meta property="og:description" content="৪০৪ ইরোর " />
                    <meta property="og:image" content="https://www.anun.xyz/assets/images/icon.png" />

                    <meta property="twitter:url" content="https://rider.anun.xyz/NotFound"></meta>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="৪০৪ ইরোর " />
                    <meta name="twitter:description" content="৪০৪ ইরোর " />
                    <meta name="twitter:image" content="https://www.anun.xyz/assets/images/icon.png" />
                    
                </Helmet>
               


  
  
  <div id="main">
    	<div class="fof">
        		<h1>৪০৪ খুঁজে পাওয়া যায়নি</h1>
    	
  <p> আমরা সেটি খুঁজে পাচ্ছি না যেটি আপনি খুঁজছেন </p>
   
    <Link to="/" className="btn btn-success">
      হোমপেজে ফিরে চলুন 
    </Link>  
     
    
</div>
</div>
</div>
  
 


            

        )
    }


}

export default NotFound