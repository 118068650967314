// eslint-disable-next-line
import React from 'react'
import { Link } from 'react-router-dom'
class Footer extends React.Component {
    render() {
        return (
            <div>
                {/* footer area */}
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="footer-inner pt100 pb60">
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <img src="assets/images/footer.svg" height={50} weidth={38} alt="" />
                                            </div>
                                            <div className="footer-info">
                                                <p>আনুন অক্সিজেন </p>

                                                <p>ঢাকা, বাংলাদেশ </p>&nbsp;
                                                <p> hello@anun.xyz</p>
                                                &nbsp;
                                                <p>+৮৮০ ৯৬৯৬৮৫৫০০১ </p>
                                                &nbsp;
                                                &nbsp;
                                               
                                                <center><p>রাইডার টেলিগ্রাম গ্রুপ </p>
                                                 <Link class='social-icon-card-github'to={{ pathname: "https://t.me/anun_rider" }} target="_blank"><i class='fab fa-telegram'style={{color: "white" , fontSize:"55px"}} /> </Link> </center>
                                                </div>
                                        </div>

                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <h2>কুইক লিংক</h2>
                                            </div>
                                            <div className="footer-menu">
                                                <ul className="list-unstyled">
                                                    <li><Link to={{ pathname: "https://rider.anun.xyz" }} target="_parent">আয় করুন </Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Partner" }} target="_parent">পার্টনার হতে চাইলে </Link></li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Livechat" }} target="_parent">লাইভ সাপোর্ট </Link> </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <h2>কোম্পানি তথ্য</h2>
                                            </div>
                                            <div className="footer-menu">
                                                <ul className="list-unstyled">
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Partner" }} target="_parent">আমাদের গল্প</Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Careers" }} target="_parent">ক্যারিয়ার</Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Brand" }} target="_parent">ব্র্যান্ড গাইডলাইন</Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Events" }} target="_parent">ইভেন্ট</Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Leadership" }} target="_parent" >লিডারশীপ</Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Media" }} target="_parent">মিডিয়া কভারেজ</Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Investors" }} target="_parent">ইনভেস্টর রিলেশন</Link> </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="footer-title">
                                                <h2>অন্যান্য</h2>
                                            </div>
                                            <div className="footer-menu">
                                                <ul className="list-unstyled">
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Privacy" }} target="_parent">প্রাইভেসি পলিসি <span className="badge badge-light">নতুন</span></Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Terms" }} target="_parent">ব্যবহারের শর্তাবলি</Link> </li>
                                                    <li><Link to={{ pathname: "https://www.anun.xyz/Guideline" }} target="_parent" >ব্যবহার নির্দেশিকা</Link> </li>

                                                    <br />
                                                    <li>
                                                        <i className="fas fa-map-marker-alt" /> ১০০% গ্লোবাল স্টার্টআপ
</li>                                                     
<center>
                                                        <li> <span className="badge badge-light">সহযোগিতায়</span> <a href="https://nagad.com.bd/merchant/anun" target="_parent"> <img src="/assets/images/nagad.png" alt="nagad-verification-anun" title="nagad-verification-anun" height="115px" width="auto" /></a> </li>
                                                    
                                                         <li>   <img src="/assets/images/microsoft.png" alt="supported by microsoft" align="center" height="110" width="255" /> </li>
                                                         </center>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copy-right-part">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="footer-inner pt30 pb30">
                                            <div className="row">
                                                <div className="col-12 col-md-6 order-2">
                                                    <ul className="list-inline app-download-link">
                                                        <h3><span style={{ color: 'white' }}>আনুন অ্যাপ রাখুন</span> <span style={{ color: '#01af4d' }}>আপনার পকেটে! </span></h3>

                                                        <li className="list-inline-item">
                                                            <a href="https://play.google.com/store/apps/details?id=apprider.anun.xyz&hl=en&gl=US" target="_parent"><img src="assets/images/play-store.png" alt="play store" /></a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a href="https://play.google.com/store/apps/details?id=apprider.anun.xyz&hl=en&gl=US" target="_parent"><img src="assets/images/app-galary.png" height={150} weidth={138} alt="app galary" /></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 order-1">
                                                    <div className="copy-right">
                                                        <p>  কপিরাইট  <i className="far fa-copyright" /> আনুন লিমিটেড ২০১৯-২০২২। সর্বস্বত্ত সংরক্ষিত।{" "} </p>
                                                        <p className="made-in-bd">  বাংলাদেশে তৈরী <i className="far fa-heart" /> ইংল্যান্ড ও ওয়েলসের রেজিস্ট্রার অব কোম্পানিজের নিকট নিবন্ধিত।</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}
export default Footer